
import {computed, defineComponent, ref} from "vue";
import {useLoginStore} from "@/store/login.store";
import {PinaFilterObjects} from "@/core/composite/composite";
import Entities from "@/components/base/common/Entities.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "Logins",
  components: {DateTimeFormat, KtDatatable, Avatar, Entities},
  props: {
    teamId: {}
  },
  setup(props) {
    const store = useLoginStore();
    const filterObject = ref({teamId: props.teamId});
    const page = computed(()=>store.page);
    const tableHeader = ref([
      {
        name: "User",
        key: "user",
        sortable: false,
      },
      {
        name: "Time",
        key: "time",
        sortable: false,
      },

      {
        name: "Location",
        key: "location",
        sortable: false,
      },
      {
        name: "Coordinates",
        key: "coordinates",
        sortable: false,
      },
      {
        name: "Access",
        key: "accessAble",
        sortable: false,
      },
      {
        name: "User Agent",
        key: "userAgent",
        sortable: false,
      }
    ]);
    return {
      tableHeader,
      page,
      ...PinaFilterObjects(store, filterObject.value),
    }
  }
})
